const API_URL = process.env.REACT_APP_API_URL;

export const fetchAcademicEntities = async () => {
    const response = await fetch(`${API_URL}/academic-entities`);
    if (!response.ok) {
        throw new Error('Failed to fetch');
    }
    const data = await response.json();
    return data;
};
