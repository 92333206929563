import React from 'react';
import AcademicEntities from './components/AcademicEntities';

function App() {
    return (
        <div>
            <h1>Список академических сущностей</h1>
            <AcademicEntities />
        </div>
    );
}

export default App;
