import React, { useState } from 'react';
import { fetchAcademicEntities } from '../services/api';

const AcademicEntities = () => {
    const [entities, setEntities] = useState([]);

    const handleFetchEntities = async () => {
        const data = await fetchAcademicEntities();
        setEntities(data);
    };

    return (
        <div>
            <button onClick={handleFetchEntities}>Показать все записи</button>
            <ul>
                {entities.map(entity => (
                    <li key={entity.academic_entity_id}>{entity.name}</li>
                ))}
            </ul>
        </div>
    );
};

export default AcademicEntities;
